import React from 'react';
import { FretamentoTab } from '../../features/Home/Fretamento/FretamentoTab';
import { Flex } from '../Flex/Flex';
import { TabOptions } from '../../interfaces/Fretamento/fretamento';
import { useCarousel } from '../../utils/hooks/useCarousel';
import { useRoteiro } from '../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
interface Props {
  selectedTab: TabOptions;
  handleSelectedTab: (tab: TabOptions) => void;
  handleOpenModal?: () => void;
  isHome?: boolean;
}
export const TabSelector = ({
  selectedTab,
  handleSelectedTab,
  isHome,
  handleOpenModal
}: Props): JSX.Element => {
  const { emblaRef } = useCarousel({ dragFree: true });
  const { handleShowModal } = useRoteiro();
  return (
    <div
      ref={emblaRef}
      className="relative w-full cursor-pointer min-h-[64px] lg:min-h-[40px] gap-8  z-[1] flex-nowrap overflow-x-hidden"
    >
      <Flex className="w-full">
        <FretamentoTab
          isHome={isHome}
          isSelected={selectedTab === 'roundtrip'}
          text="Ida e Volta"
          value="roundtrip"
          onClick={(tab) => handleSelectedTab(tab as unknown as TabOptions)}
        />
        <FretamentoTab
          isHome={isHome}
          isSelected={selectedTab === 'onewaytrip'}
          text="Somente Ida"
          value="onewaytrip"
          onClick={(tab) => handleSelectedTab(tab as unknown as TabOptions)}
        />
        <FretamentoTab
          isHome={isHome}
          isSelected={selectedTab === 'customtrip'}
          text="Roteiro Personalizado"
          value="customtrip"
          onClick={() => {
            handleShowModal(true);
          }}
        />
      </Flex>
    </div>
  );
};
