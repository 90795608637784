//@ts-nocheck
import React from 'react';
import FormTextField, { FormTextFieldProps } from './FormTextField';
import { Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { Flex } from '../Flex/Flex';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
interface Props<T extends FieldValues> extends FormTextFieldProps<T> {
  title: string;
  text: string;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
}

const getErrorMessage = (
  errors: FieldErrors,
  name: string
): string | undefined => {
  const path = name.replace(/\[(\w+)\]/g, '.$1').split('.');
  return path.reduce((acc: any, part: string) => acc && acc[part], errors)
    ?.message;
};

export const TrajetoField = <T extends FieldValues>(
  props: Props<T>
): JSX.Element => {
  const errorMessage = getErrorMessage(props.errors, props.name);

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {
        const { ref, ...inputProps } = field;
        return (
          <label
            className={` ${
              props.errors?.[props.name] || errorMessage
                ? 'border-alternative-error'
                : 'border-neutral-pureOp10'
            } bg-white dark:bg-white h-fit w-full input input-bordered p-3 flex flex-col gap-1`}
          >
            <Flex className="items-center gap-[6px] w-full">
              <Flex direction="col" className="gap-1 relative w-full">
                <p className="h7 lg:h6 text-neutral-pure700">{props.title}</p>
                <Flex className="gap-[6px] items-center w-full">
                  {props.icon}

                  <ReactGoogleAutocomplete
                    apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
                    className="w-full dark:bg-white bg-white placeholder:text-neutral-pure1000 placeholder:bodysm placeholder:font-[500]"
                    placeholder={props.text}
                    {...inputProps}
                    {...props}
                    value={field.value?.address || ''}
                    onChange={(e) => {
                      inputProps.onChange({
                        ...field.value,
                        address: e.target.value
                      });
                    }}
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'br' }
                    }}
                    onPlaceSelected={(place) => {
                      if (!place) return;
                      const addressComponents = place.address_components;
                      const getAddressComponent = (type, long = true) =>
                        long
                          ? addressComponents.find((component) =>
                              component.types.includes(type)
                            )?.long_name
                          : addressComponents.find((component) =>
                              component.types.includes(type)
                            )?.short_name;

                      inputProps.onChange({
                        ...field.value,
                        name: getAddressComponent('route'),
                        address: place.formatted_address,
                        city: getAddressComponent(
                          'administrative_area_level_2'
                        ),
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                        address_components: {
                          route: getAddressComponent('route'),
                          state: getAddressComponent(
                            'administrative_area_level_1'
                          ),
                          state_short: getAddressComponent(
                            'administrative_area_level_1',
                            false
                          ),
                          city: getAddressComponent(
                            'administrative_area_level_2'
                          ),
                          district: getAddressComponent('sublocality_level_1'),
                          postal_code: getAddressComponent('postal_code')
                        }
                      });
                    }}
                  />
                  {props.iconRight}
                </Flex>
              </Flex>
            </Flex>
          </label>
        );
      }}
    />
  );
};
