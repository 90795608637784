import React from 'react'
import { Flex } from '../../../components/Flex/Flex'

export const RoteiroPersonalizado = () => {
  return (
    <Flex direction="col" className="gap-2 w-full justify-center items-center py-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
        <g opacity="0.8">
          <path d="M71.175 91.65L66.3 85.15C66.1488 84.9481 65.9525 84.7842 65.7268 84.6713C65.5012 84.5585 65.2523 84.4998 65 84.5H38.1875C36.2481 84.5 34.3881 83.7296 33.0168 82.3582C31.6454 80.9869 30.875 79.1269 30.875 77.1875C30.875 75.2481 31.6454 73.3881 33.0168 72.0168C34.3881 70.6454 36.2481 69.875 38.1875 69.875H43.875C48.183 69.8692 52.3128 68.1552 55.359 65.109C58.4052 62.0628 60.1192 57.933 60.125 53.625C60.1201 49.3167 58.4065 45.1863 55.3601 42.1399C52.3137 39.0935 48.1833 37.3799 43.875 37.375H29.0712C28.828 37.375 28.5878 37.4295 28.3684 37.5347C28.149 37.6399 27.9561 37.793 27.8038 37.9828L22.5875 44.4828C22.3957 44.7217 22.2754 45.0099 22.2404 45.3142C22.2053 45.6186 22.2571 45.9266 22.3896 46.2028C22.5221 46.479 22.73 46.7122 22.9892 46.8753C23.2485 47.0385 23.5486 47.1251 23.855 47.125H43.875C45.5989 47.125 47.2522 47.8098 48.4712 49.0288C49.6902 50.2478 50.375 51.9011 50.375 53.625C50.375 55.3489 49.6902 57.0022 48.4712 58.2212C47.2522 59.4402 45.5989 60.125 43.875 60.125H38.1875C33.6622 60.125 29.3223 61.9227 26.1225 65.1225C22.9227 68.3223 21.125 72.6622 21.125 77.1875C21.125 81.7128 22.9227 86.0527 26.1225 89.2525C29.3223 92.4524 33.6622 94.25 38.1875 94.25H69.875C70.1768 94.25 70.4726 94.166 70.7293 94.0073C70.986 93.8487 71.1935 93.6216 71.3284 93.3517C71.4634 93.0818 71.5205 92.7796 71.4934 92.4791C71.4663 92.1785 71.3561 91.8914 71.175 91.65Z" fill="#E9E9E9" />
          <path d="M60.125 71.5C59.694 71.5 59.2807 71.3288 58.976 71.024C58.6712 70.7193 58.5 70.306 58.5 69.875V66.625C58.501 65.7634 58.8437 64.9373 59.453 64.328C60.0623 63.7187 60.8884 63.376 61.75 63.375H65C65.431 63.375 65.8443 63.5462 66.149 63.851C66.4538 64.1557 66.625 64.569 66.625 65C66.625 65.431 66.4538 65.8443 66.149 66.149C65.8443 66.4538 65.431 66.625 65 66.625H61.75V69.875C61.75 70.306 61.5788 70.7193 61.274 71.024C60.9693 71.3288 60.556 71.5 60.125 71.5Z" fill="#B5B5B5" />
          <path d="M99.125 71.5C98.694 71.5 98.2807 71.3288 97.976 71.024C97.6712 70.7193 97.5 70.306 97.5 69.875V66.625H94.25C93.819 66.625 93.4057 66.4538 93.101 66.149C92.7962 65.8443 92.625 65.431 92.625 65C92.625 64.569 92.7962 64.1557 93.101 63.851C93.4057 63.5462 93.819 63.375 94.25 63.375H97.5C98.3616 63.376 99.1877 63.7187 99.797 64.328C100.406 64.9373 100.749 65.7634 100.75 66.625V69.875C100.75 70.306 100.579 70.7193 100.274 71.024C99.9693 71.3288 99.556 71.5 99.125 71.5Z" fill="#B5B5B5" />
          <path d="M65 65H94.25V79.625H65V65Z" fill="#BCBCBC" />
          <path d="M83.8663 65C83.0556 70.0944 81.4489 75.0297 79.105 79.625H65V65H83.8663Z" fill="#BCBCBC" />
          <path d="M94.25 61.75V65H65V61.75C65.0025 60.8888 65.3458 60.0636 65.9547 59.4547C66.5636 58.8458 67.3888 58.5025 68.25 58.5H91C91.8612 58.5025 92.6864 58.8458 93.2953 59.4547C93.9042 60.0636 94.2475 60.8888 94.25 61.75Z" fill="#CDCDCD" />
          <path d="M84.4838 58.5C84.4189 60.6778 84.2126 62.849 83.8663 65H65V61.75C65.0025 60.8888 65.3458 60.0636 65.9547 59.4547C66.5636 58.8458 67.3888 58.5025 68.25 58.5H84.4838Z" fill="#E9E9E9" />
          <path d="M94.25 79.625V86.125C94.2475 86.9862 93.9042 87.8114 93.2953 88.4203C92.6864 89.0292 91.8612 89.3725 91 89.375H68.25C67.3888 89.3725 66.5636 89.0292 65.9547 88.4203C65.3458 87.8114 65.0025 86.9862 65 86.125V79.625H94.25Z" fill="#CDCDCD" />
          <path d="M79.105 79.625C77.3283 83.1187 75.1467 86.3913 72.605 89.375H68.25C67.3888 89.3725 66.5636 89.0292 65.9547 88.4203C65.3458 87.8114 65.0025 86.9862 65 86.125V79.625H79.105Z" fill="#E9E9E9" />
          <path d="M73.125 86.125H71.5C71.069 86.125 70.6557 85.9538 70.351 85.649C70.0462 85.3443 69.875 84.931 69.875 84.5C69.875 84.069 70.0462 83.6557 70.351 83.351C70.6557 83.0462 71.069 82.875 71.5 82.875H73.125C73.556 82.875 73.9693 83.0462 74.274 83.351C74.5788 83.6557 74.75 84.069 74.75 84.5C74.75 84.931 74.5788 85.3443 74.274 85.649C73.9693 85.9538 73.556 86.125 73.125 86.125Z" fill="#B5B5B5" />
          <path d="M87.75 86.125H86.125C85.694 86.125 85.2807 85.9538 84.976 85.649C84.6712 85.3443 84.5 84.931 84.5 84.5C84.5 84.069 84.6712 83.6557 84.976 83.351C85.2807 83.0462 85.694 82.875 86.125 82.875H87.75C88.181 82.875 88.5943 83.0462 88.899 83.351C89.2038 83.6557 89.375 84.069 89.375 84.5C89.375 84.931 89.2038 85.3443 88.899 85.649C88.5943 85.9538 88.181 86.125 87.75 86.125Z" fill="#B5B5B5" />
          <path d="M68.25 89.375H74.75V92.625C74.75 93.056 74.5788 93.4693 74.274 93.774C73.9693 94.0788 73.556 94.25 73.125 94.25H69.875C69.444 94.25 69.0307 94.0788 68.726 93.774C68.4212 93.4693 68.25 93.056 68.25 92.625V89.375Z" fill="#D0D0D0" />
          <path d="M91 89.375V92.625C90.9988 93.0556 90.8272 93.4682 90.5227 93.7727C90.2182 94.0772 89.8056 94.2488 89.375 94.25H86.125C85.6944 94.2488 85.2818 94.0772 84.9773 93.7727C84.6728 93.4682 84.5012 93.0556 84.5 92.625V89.375H91Z" fill="#D0D0D0" />
          <path d="M88.8225 89.375C88.5155 91.2173 87.6472 92.9198 86.3363 94.25H86.125C85.6944 94.2488 85.2818 94.0772 84.9773 93.7727C84.6728 93.4682 84.5012 93.0556 84.5 92.625V89.375H88.8225Z" fill="#B5B5B5" />
          <path d="M72.5725 89.375C72.2655 91.2173 71.3972 92.9198 70.0863 94.25H69.875C69.4444 94.2488 69.0318 94.0772 68.7273 93.7727C68.4228 93.4682 68.2512 93.0556 68.25 92.625V89.375H72.5725Z" fill="#B5B5B5" />
          <path d="M22.2084 9.68164C18.3694 9.7711 14.7225 11.3787 12.0666 14.1522C9.41076 16.9257 7.96263 20.6388 8.03953 24.4781C8.03953 35.3865 20.5421 47.701 21.0737 48.2193C21.3772 48.5154 21.7843 48.6811 22.2083 48.6811C22.6323 48.6811 23.0395 48.5154 23.343 48.2193C23.8747 47.7005 36.3771 35.3861 36.3771 24.4781C36.454 20.6388 35.006 16.9257 32.3501 14.1522C29.6943 11.3787 26.0474 9.7711 22.2084 9.68164Z" fill="#D3D3D3" />
          <path d="M26.7853 10.5272C26.6142 15.8089 25.2117 20.9777 22.6895 25.6215C20.1673 30.2653 16.5951 34.2557 12.2578 37.2747C9.69401 33.4692 8.19076 29.0492 7.90281 24.4697C7.82594 20.6311 9.2745 16.9187 11.9308 14.1466C14.5872 11.3745 18.2344 9.769 22.0728 9.68217C23.6817 9.67827 25.278 9.96452 26.7853 10.5272Z" fill="#C8C8C8" />
          <path d="M22.2085 32.856C26.6958 32.856 30.3335 29.2183 30.3335 24.731C30.3335 20.2436 26.6958 16.606 22.2085 16.606C17.7212 16.606 14.0835 20.2436 14.0835 24.731C14.0835 29.2183 17.7212 32.856 22.2085 32.856Z" fill="#BCBCBC" />
          <path d="M25.8263 17.5318C24.5154 22.8724 21.9154 27.8102 18.2539 31.9131C16.6539 31.0602 15.3848 29.6971 14.6483 28.0403C13.9119 26.3835 13.7502 24.5281 14.1891 22.7689C14.6279 21.0098 15.6421 19.4477 17.0705 18.331C18.4989 17.2143 20.2595 16.607 22.0726 16.6055C23.3811 16.5996 24.6708 16.9179 25.8263 17.5318Z" fill="#B1B1B1" />
        </g>
      </svg>
      <p className="text-center text-neutral-pure600 bodysm">Preencha as informações abaixo e prossiga para criar seu roteiro personalizado</p>
    </Flex>
  )
}
