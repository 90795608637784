import React from 'react';
import { Flex } from '../../../components/Flex/Flex';
import { TabOptions } from '../../../interfaces/Fretamento/fretamento';

interface Props {
  isSelected: boolean;
  text: string;
  value: string;
  onClick: (value: string) => void;
  isHome?: boolean;
  icon?: React.ReactNode;
}

export const FretamentoTab = ({
  isHome = true,
  isSelected,
  text,
  value,
  onClick,
  icon
}: Props) => {
  const style = isHome ? 'w-full p-5 h5 py-2' : ' p-5 lg:p-2 h5 lg:h6';
  return (
    <button
      type="button"
      className={`text-start text-nowrap min-w-fit  ${style} font-[500] ${isSelected ? `border-b-[2px] border-[color:var(--border-primary-pure)] ${icon ? `text-neutral-pure1000` : `text-[color:var(--text-primary-pure)]`}` : 'border-b-[1px] border-neutral-pureOp10 text-neutral-pure400 cursor-pointer'}`}
      onClick={() => onClick(value)}
    >
      <Flex className="items-center justify-center gap-1">
        <div
          className={isSelected ? `text-[color:var(--text-primary-pure)]` : `text-neutral-pure400`}
        >
          {icon}
        </div>
        {text}
      </Flex>
    </button>
  );
};
