import React, { useEffect, useState } from 'react';
import { Controller, FieldErrors, FieldValues } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Flex } from '../Flex/Flex';
import { FormTextFieldProps } from './FormTextField';
import { ptBR } from 'date-fns/locale/pt-BR';
import { getYear, getMonth } from 'date-fns';
import Icons from '../../../public/assets/icons';

registerLocale('pt-BR', ptBR);

interface DatePickerFieldProps<T extends FieldValues>
  extends Omit<FormTextFieldProps<T>, 'iconLeft' | 'iconRight'> {
  title: string;
  text: string;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
}

const getErrorMessage = (
  errors: FieldErrors,
  name: string
): string | undefined => {
  const path = name.replace(/\[(\w+)\]/g, '.$1').split('.');
  return path.reduce((acc: any, part: string) => acc && acc[part], errors)
    ?.message;
};

interface ExampleCustomTimeInputProps {
  date: Date;
  value?: string; // Optional if you're not using it, but included for completeness
  onChange: (timeString: string) => void;
}

const ExampleCustomTimeInput = ({ date, value, onChange }: ExampleCustomTimeInputProps) => {
  const [localHour, setLocalHour] = useState(date ? date.getHours().toString().padStart(2, '0') : '');
  const [localMinute, setLocalMinute] = useState(date ? date.getMinutes().toString().padStart(2, '0') : '');

  // Function to format and update the parent DatePicker with a time string
  const saveTime = () => {
    const formattedTime = `${localHour.padStart(2, '0')}:${localMinute.padStart(2, '0')}`;
    onChange(formattedTime); // Pass the formatted time string to onChange
  };

  // Update local state when prop date changes
  useEffect(() => {
    if (date) {
      setLocalHour(date.getHours().toString().padStart(2, '0'));
      setLocalMinute(date.getMinutes().toString().padStart(2, '0'));
    }
  }, [date]);

  return (
    <Flex className='bg-white flex-col gap-2  w-full max-w-[263px] justify-center'>
      <p className='text-neutral-pure1000 bodysm'>Informe o horário</p>
      <Flex className='gap-2 items-center justify-between w-full'>
        <Flex className='w-full p-2 flex-col gap-1 border-[1px] border-neutral-pureOp10 rounded-[12px]'>
          <p className='text-neutral-pure700 h6'>Horas</p>
          <input
            type="number"
            className="w-full text-neutral-pure1000 bodysm"
            value={localHour}
            onChange={(e) => {
              const isValueValid = e.target.value.length <= 2 && Number(e.target.value) <= 23 && Number(e.target.value) >= 0;
              return isValueValid ? setLocalHour(e.target.value) : null
            }}
            placeholder="HH"
          />
        </Flex>
        <Flex className='w-full p-2 flex-col gap-1 border-[1px] border-neutral-pureOp10 rounded-[12px]'>
          <p className='text-neutral-pure700 h6'>Minutos</p>
          <input
            type="number"
            className="w-full text-neutral-pure1000 bodysm"
            value={localMinute}
            onChange={(e) => {
              const isValueValid = e.target.value.length <= 2 && Number(e.target.value) <= 59 && Number(e.target.value) >= 0;
              return isValueValid ? setLocalMinute(e.target.value) : null
            }}
            placeholder="MM"
          />
        </Flex>
      </Flex>
      <button type='button' className='w-full bg-[color:var(--bg-primary-pure)] text-white h-10 rounded-[6px] bodysm' onClick={saveTime}>Confirmar</button>
    </Flex>
  );
};


const range = (start: number, end: number, step = 1) => {
  const output = [];
  for (let i = start; i <= end; i += step) {
    output.push(i);
  }
  return output;
};


export const DatePickerField = <T extends FieldValues>(
  props: DatePickerFieldProps<T>
): JSX.Element => {
  const errorMessage = getErrorMessage(props.errors, props.name);

  const years = range(2024, getYear(new Date()) + 1, 1);
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];



  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {
        const { onChange, onBlur, value } = field;
        return (
          <label
            className={` input input-bordered ${props.errors?.[props.name] || errorMessage ? 'border-alternative-error' : 'border-neutral-pureOp10'} bg-white dark:bg-neutral-pure h-fit w-full p-3 flex flex-col gap-1`}
          >
            <Flex className="items-center gap-[6px] w-full">
              <Flex direction="col" className="gap-1 relative w-full">
                <p className="h7 lg:h6 text-neutral-pure700">{props.title}</p>
                <Flex className="gap-[6px] items-center w-full">
                  {props.icon}
                  <DatePicker
                    locale={ptBR}
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <Flex
                        className='w-full justify-between gap-4 bg-white'
                      >
                        <Flex className='items-center gap-4'>
                          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            <Icons.CircleChevronLeft />
                          </button>


                          <select
                            value={months[getMonth(date)]}
                            className='appearance-none text-center p-1 text-neutral-pure1000 h5'
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            <Icons.CircleChevronLeft className="transform rotate-180" />
                          </button>
                        </Flex>
                        <select
                          value={getYear(date)}
                          className='h-8 rounded-[6px] w-[81px] pl-[14px] border-[1px] border-neutral-pureOp10 text-neutral-pure600 bodysm'

                          onChange={({ target: { value } }) => changeYear(value as unknown as number)}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </Flex>
                    )}
                    selected={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    dateFormat={'Pp'}
                    showTimeInput
                    popperPlacement='bottom'
                    calendarClassName={'w-[301px] datepicker bg-white p-2 '}
                    timeInputLabel=''
                    customTimeInput={
                      <ExampleCustomTimeInput onChange={onChange} date={value || new Date()} />
                    }
                    placeholderText={props.text}
                  />
                  {props.iconRight}
                </Flex>
              </Flex>
            </Flex>
          </label>
        );
      }}
    />
  );
};
