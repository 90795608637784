import React from 'react';
import { TabSelector } from '../../../components/TabSelector/TabSelector';
import { IdaEVolta } from '../../Trajeto/IdaEVolta';
import { SomenteIda } from '../../Trajeto/SomenteIda';
import { TabOptions } from '../../../interfaces/Fretamento/fretamento';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFieldArrayAppend
} from 'react-hook-form';
import { PorHora } from '../../Trajeto/PorHora';
import { RoteiroPersonalizado } from '../../Trajeto/RoteiroPersonalizado/RoteiroPersonalizado';

interface Props<T extends FieldValues> {
  selectedTab: TabOptions;
  handleSelectedTab: (tab: TabOptions) => void;
  control: Control<T>;
  fields: Record<'id', string>[];
  append: UseFieldArrayAppend<any>;
  remove: (index: number) => void;
  errors: FieldErrors<T>;
  isHome?: boolean;
  isFilter?: boolean;
}

export const FretamentoWrapper = <T extends FieldValues>({
  selectedTab,
  handleSelectedTab,
  append,
  control,
  errors,
  fields,
  remove,
  isHome = false,
  isFilter = false
}: Props<T>) => {
  return (
    <>
      <TabSelector
        handleSelectedTab={handleSelectedTab}
        selectedTab={selectedTab}
        isHome={isHome}
      />

      {selectedTab === 'roundtrip' && (
        <IdaEVolta
          fields={fields}
          append={append}
          remove={remove}
          control={control}
          errors={errors}
          isFilter={isFilter}
        />
      )}
      {selectedTab === 'onewaytrip' && (
        <SomenteIda
          fields={fields}
          append={append}
          remove={remove}
          control={control}
          errors={errors}
          isFilter={isFilter}
        />
      )}
      {selectedTab === 'customtrip' && <RoteiroPersonalizado />}
    </>
  );
};
