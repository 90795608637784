import React from 'react';
import { FormTextFieldProps } from '../../components/FormTextField/FormTextField';
import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form';
import { TrajetoField } from '../../components/FormTextField/TrajetoField';
import Icons from '../../../public/assets/icons';
import { Flex } from '../../components/Flex/Flex';
import { DatePickerField } from '../../components/FormTextField/DatePickerField';
import AutoComplete from 'react-google-autocomplete';

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  fields: Record<'id', string>[];
  remove: (index: number) => void;
  append: (value: Record<'nome', string>) => void;
  isFilter?: boolean;
}

export const IdaEVolta = <T extends FieldValues>({
  control,
  errors,
  fields,
  append,
  remove,
  isFilter = false
}: Props<T>): JSX.Element => {
  return (
    <Flex direction="col" className="gap-6 w-full">
      <Flex direction="col" className="gap-4">
        <Flex className="gap-4  pb-4">
          <Flex direction="col" className="gap-2 w-full">
            <Flex className="w-full gap-4">
              <Flex direction="col" className="w-6 items-center">
                <Flex className="w-6 h-6">
                  <Icons.BlueBus className="text-[color:var(--text-primary-pure)] w-6 h-6" />
                </Flex>
                <div className="w-1 h-full bg-neutral-pure300 rounded-[10px]" />
              </Flex>
              <Flex
                className={`w-full gap-4 flex-col ${isFilter ? '' : 'lg:flex-row'}`}
              >
                <TrajetoField
                  control={control}
                  errors={errors}
                  name={'origem' as Path<T>}
                  title="Origem"
                  text="De onde você sairá?"
                  icon={<Icons.BlueMapPinSmall className="text-[color:var(--text-primary-pure)] w-5 h-5" />}
                />

                <DatePickerField
                  control={control}
                  errors={errors}
                  name={'dataIda' as Path<T>}
                  title="Data e horário de ida?"
                  text="Quando você vai?"
                  icon={<Icons.BlueCalendarSmall className="text-[color:var(--text-primary-pure)] w-5 h-5" />}
                />
              </Flex>
            </Flex>

            {fields.map((field, index) => (
              <Flex className="w-full gap-4" key={field.id}>
                <Flex direction="col" className="w-6 items-center">
                  <Flex className="w-6 h-6">
                    <Icons.BluePerson className="text-[color:var(--text-primary-pure)] w-6 h-6" />
                  </Flex>
                  <div className="w-1 h-full bg-neutral-pure300 rounded-[10px]" />
                </Flex>
                <TrajetoField
                  control={control}
                  errors={errors}
                  name={`embarques[${index}]` as Path<T>}
                  title="Ponto de embarque ou desembarque"
                  text="Informe o local"
                  icon={<Icons.BlueMallet className="text-[color:var(--text-primary-pure)] w-5 h-5" />}
                  iconRight={
                    <>
                      <Icons.RemoveEmbarque
                        className="text-[color:var(--text-primary-pure)] hidden lg:block min-w-12 w-12 h-12 absolute right-0 top-0 cursor-pointer"
                        onClick={() => remove(index)}
                      />
                      <Icons.RemoveEmbarqueSmall
                        className="text-[color:var(--text-primary-pure)] lg:hidden min-w-8 w-8 h-8  absolute right-0 top-1 cursor-pointer"
                        onClick={() => remove(index)}
                      />
                    </>
                  }
                />
              </Flex>
            ))}
            <button
              type="button"
              onClick={() => append({ nome: '' })} // Append a new "embarque" with default values
            >
              <Flex className="pl-[2px] w-full text-[color:var(--text-primary-pure)] h6 lg:items-center gap-4 lg:gap-2 py-2">
                <Icons.BlueAdd className="min-w-5 w-5 h-5" />
                <p className="text-start mt-[1px]">
                  Adicionar pontos de embarque ou desembarque
                </p>
              </Flex>
            </button>
            <Flex className="w-full gap-4">
              <Flex direction="col" className="w-6 items-center">
                <Flex className="w-6 h-6">
                  <Icons.BlueMapPin className="w-6 h-6 text-[color:var(--text-primary-pure)]" />
                </Flex>
                <div className="w-1 h-full bg-neutral-pure300 rounded-[10px]" />
              </Flex>
              <Flex
                className={`w-full gap-4 flex-col ${isFilter ? '' : 'lg:flex-row'}`}
              >
                <TrajetoField
                  control={control}
                  errors={errors}
                  name={'destino' as Path<T>}
                  title="Destino"
                  text="Para onde você quer ir?"
                  icon={
                    <Icons.BlueBusSmall className="text-[color:var(--text-primary-pure)] w-5 h-5 min-w-5" />
                  }
                />
                <DatePickerField
                  control={control}
                  errors={errors}
                  name={'dataVolta' as Path<T>}
                  title="Data e horário de volta"
                  text="Quando você volta?"
                  icon={<Icons.BlueCalendarSmall className="text-[color:var(--text-primary-pure)] w-5 h-5 min-w-5" />}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
